
// ==============================|| Toast Message ||============================== //

import { CheckCircleFilled, UploadOutlined } from "@ant-design/icons";
import { Badge, Box, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

const ToastMessage = ({ message }) => {
  // const theme = useTheme();

  return (
    <>
      {message === 'pending' ? (<Box sx={{ display: "flex", alignItems: "center", gap: 3 }}>
        <Badge badgeContent={<CheckCircleFilled style={{ color: "#F09D0D" }} />} max={999} showZero={false} sx={{
          '& .MuiBadge-badge': {
            marginTop: '-2px',
            marginRight: '-1px',
            fontSize: "16px"
          },
          marginLeft: '10px'
        }}>
          <Box sx={{ color: "text.primary", fontSize: "20px" }}>
            <UploadOutlined />
          </Box>
        </Badge>
        <Box>
          <Typography sx={{ fontWeight: "bold", fontSize: "14px",color: "text.primary" }}>
            <FormattedMessage id='activity_center_list_title_in_progress' />
          </Typography>
          <Typography sx={{ fontSize: "12.5px", color: "text.primary" }}>
            <FormattedMessage id='request_initiated_message' values={{
              br: <br />
            }} />
          </Typography>
        </Box>
      </Box>
      ) : message === 'completed' ? (<Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
        <Badge badgeContent={<CheckCircleFilled style={{ color: "green" }} />} max={999} showZero={false} sx={{
          '& .MuiBadge-badge': {
            // marginTop: '-2px',
            // marginRight: '-1px',
          },
        }}>
          <Box sx={{ color: "text.primary", fontSize: "20px" }}>
            <UploadOutlined />
          </Box>
        </Badge>
        <Box display='flex' gap={1}>
            <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>
              <FormattedMessage id='activity_center_list_title_done' />
            </Typography>
          <Link sx={{ color: "#4368FF", cursor: "pointer" }}>
            view
          </Link>
        </Box>
      </Box>
      )
        : message
      }
    </>
  );
};


export default ToastMessage;
