import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import { Box, FormControl, IconButton, InputAdornment, OutlinedInput, Typography, Button } from '@mui/material';
import TuneIcon from '@mui/icons-material/Tune';
import SearchIcon from '@mui/icons-material/Search';
import { useTheme } from '@mui/material/styles';
import { useSearch } from '../../../../hooks/useSearchContext';
import { useNavigate } from 'react-router-dom';
import { BasicDetails, DocumentFields } from 'components/expandedSearch';
import { SearchTable } from 'components/SearchFieldTable';
import { FormattedMessage, useIntl } from 'react-intl';
import debounce from 'lodash/debounce';


// import hilconaData from '../../../../mapping/hilcona.json';

const Search = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const intl = useIntl();

  const [localSearchValue, setLocalSearchValue] = useState('');
  const [localSearchResults, setLocalSearchResults] = useState([]);
  const searchRef = useRef(null); // Ref now includes the search input and accordion content
  const { documentCategory } = useSearch();
  const {
    setSearchValue,
    searchValue,
    clearAllFilters,
    activeFilters,
    searchBoxApi,
    triggerSearch,
    pageIndex,
    setPageIndex,
    isSearchFocused,
    setIsSearchFocused,
    showAccordion, 
    setShowAccordion
  } = useSearch();

  const [categoryTypes, setCategoryTypes] = useState([]);

  const debouncedSearch = useCallback(
    debounce(async (value) => {      
      
      if (value.trim() !== '') {
        const results = await searchBoxApi(value);
        const categories = results.map(result => result._source.category);
        const uniqueCategories = [...new Set(categories)];

        const formattedCategories = uniqueCategories.map(category => ({
          label: category,
          value: `${category.toLowerCase()}`
        }));
        setCategoryTypes(formattedCategories);

        setLocalSearchResults(results);
      } else {
        setLocalSearchResults([]);
        setCategoryTypes([]);
      }
    }, 150),
    [searchBoxApi]
  );


  useEffect(() => {
    if (searchValue == '') {
      setLocalSearchValue('')
    }
    else{
      setLocalSearchValue(searchValue)
    }
  }, [searchValue])
  
  const handleSearch = () => {
    if(pageIndex===0){
      triggerSearch()
    }
    setPageIndex(0)
    setIsSearchFocused(false);
    navigate('/archive/search');
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      setSearchValue(localSearchValue);
      setIsSearchFocused(false);
      navigate('/archive/search');
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setLocalSearchValue(value);
    
    // Check if the input change was due to a paste action
    if (e.inputType === 'insertFromPaste') {
      // Call handleKeyDown for the paste event
      handleKeyDown(e);
    }
    if (isSearchFocused) {
      debouncedSearch(value);
    }
    if (value !== '' && value) {
      setIsSearchFocused(true);
      debouncedSearch(value); // Debounce the search only if search is focused
    } else {
      setIsSearchFocused(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if the click occurred outside the entire searchRef (both search box and accordion)
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        // setShowAccordion(false);
        setIsSearchFocused(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setIsSearchFocused(false);
  }, [documentCategory]);

  const escapeRegExp = (string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // Escape special characters
  };
  
  const highlightText = (text, highlight) => {
    if (!highlight) return text;
  
    const escapedHighlight = escapeRegExp(highlight); // Escape special characters for safe regex usage
    const regex = new RegExp(`(${escapedHighlight})`, 'gi'); // Create regex with escaped highlight text
    const parts = text.split(regex); // Split the text based on the regex
    
    return parts.map((part, index) =>
      regex.test(part) ? (
         <span key={index} style={{ backgroundColor: theme.palette.action.selected, padding: '2px 0' }}>{part}</span>
      ) : (
        part
      )
    );
  };

  const filteredColumns = useMemo(() => {
    return [
      {
        accessor: 'combined',
        Cell: ({ row }) => {
          const documentNumber = row.original._source.document_number || '';
          const companyName = row.original._source.name || '';

          return (
            <Box
              onClick={(e) => {
                e.stopPropagation();
                navigate(`/details/${row.original._id}`, {
                  state: { results: localSearchResults, searchValue: localSearchValue, itemData: row.original }
                });
                setIsSearchFocused(false);
              }}
              style={{ cursor: 'pointer' }}
            >
              <Typography variant='caption'>
                <FormattedMessage id='document_number' />:{" "}
                <span style={{ fontWeight: '600' }}>
                  {highlightText(documentNumber, localSearchValue)}
                </span>
              </Typography>
              <br />
              <Typography variant='caption'>
                <FormattedMessage id='companyName' />:  {" "}
                <span style={{ fontWeight: '600' }}>
                  {highlightText(companyName, localSearchValue)}
                </span>
              </Typography>
            </Box>
          );
        },
      },
    ];
  }, [localSearchValue]);


  return (
    <Box sx={{ width: '100%', ml: { xs: 0, md: 1 } }} ref={searchRef}>
      <FormControl sx={{ width: { xs: '100%', md: 624 } }}>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 1 }}>
          <OutlinedInput
            size="medium"
            id="header-search"
            value={localSearchValue }
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            onFocus={() => {
              setShowAccordion(false);
            }}
            startAdornment={
              <InputAdornment position="start" sx={{ mr: -0.5 }}>
                <SearchIcon />
              </InputAdornment>
            }
            endAdornment={
              <InputAdornment position="end">
                <Box
                  sx={{
                    width: '22px',
                    height: '22px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: theme.palette.primary.main,
                    fontSize: '0.8rem',
                    userSelect: 'none',
                  }}
                >
                  {activeFilters.length}
                </Box>
              </InputAdornment>
            }
            placeholder={intl.formatMessage({ id: "search_meta_data" })}
            sx={{ width: '100%', backgroundColor: 'background.paper' }}
          />
          <IconButton onClick={() => {
            setShowAccordion((prev) => !prev);
            setIsSearchFocused(false);
          }}>
            <TuneIcon sx={{ color: 'primary.main' }} />
          </IconButton>
        </Box>
      </FormControl>


      <Box
        boxShadow={2}
        borderRadius={1}
        sx={{
          // Keeping the flex display for positioning
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          width: 580,
          maxHeight: isSearchFocused && !showAccordion ? '500px' : '0', // Set a max height when focused
          overflow: isSearchFocused && !showAccordion ? 'auto' : 'hidden', // Only show overflow when focused
          position: 'absolute',
          backgroundColor: theme.palette.background.paper,
          pt: 1,
          px: 2,
          pb: 2,
          transform: isSearchFocused && !showAccordion ? 'translateY(0)' : 'translateY(-100%)',
          opacity: isSearchFocused && !showAccordion ? 1 : 0,
          transition: 'max-height 0.5s ease, opacity 0.3s ease, transform 0.5s ease', // Adding max-height to transition
          zIndex: -1,
        }}
      >
        <SearchTable
          columns={filteredColumns}
          data={localSearchResults}
          customLinePadding={0}
          resultSize={localSearchResults.length}
          tableName={"SearchTable"}
          showPagination={false}
          categoryTypes={categoryTypes}
        />
      </Box>


      {/* {showAccordion && ( */}
      <Box
        boxShadow={2}
        borderRadius={1}
        sx={{
          flex: showAccordion ? 3 : 0,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          width: 580,
          overflow: 'hidden',
          transform: showAccordion ? 'translateY(0)' : 'translateY(-100%)',
          opacity: showAccordion ? 1 : 0,
          transition: 'width 0.5s ease, opacity 0.3s ease, transform 0.5s ease',
          position: 'absolute',
          backgroundColor: theme.palette.background.paper,
          p: 2,
          mt: 1,
          zIndex: -1,
        }}
      >
        <BasicDetails />
        <DocumentFields />
        <Box
          sx={{
            position: 'sticky',
            bottom: 0,
            left: 0,
            right: 0,
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            padding: 0,
            zIndex: 1,
          }}
        >
          <Button
            variant="outlined"
            onClick={() => {
              clearAllFilters();
              setShowAccordion(false);
            }}
            sx={{ flex: 1, mr: 1 }}
          >
            <FormattedMessage id="clearAll" />
          </Button>
          <Button
            variant="contained"
            onClick={handleSearch}
            sx={{ flex: 1, ml: 1 }}
          >
            <FormattedMessage id="search" />
          </Button>
        </Box>
      </Box>
      {/* )} */}
    </Box>
  );
  
  
  
};

export default Search;
