import { useCallback, useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';

// project import
import useConfig from 'hooks/useConfig';

// ==============================|| LOCALIZATION ||============================== //
const fetchLanguage = async (lang) => {
  try {
    const response = await fetch(`/languages/${lang}.json`, { cache: 'no-store',
      headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
      }, 
    });
    return await response.json();
  } catch (error) {
    console.error(`Error fetching ${lang}.json:`, error);
    return null;
  }
};

const Locales = ({ children }) => {
  const { i18n } = useConfig();

  const [messages, setMessages] = useState();
  const [translations, setTranslations] = useState({ en: null, de: null, fr: null });

  // load locales files
  const loadLocaleData = useCallback(() => {
    switch (i18n) {
      case 'de':
        return translations.de;
      case 'fr':
        return translations.fr;
      case 'en':
      default:
        return translations.en;
    }
  },[translations,i18n]);

  useEffect(() => {
    const loadTranslations = async () => {
      const [en, de, fr] = await Promise.all([
        fetchLanguage('en'),
        fetchLanguage('de'),
        fetchLanguage('fr'),
      ]);
      setTranslations({ en, de, fr });
    };

    loadTranslations();
  }, []);

  useEffect(() => {
    const traslationJson = loadLocaleData();
    setMessages(traslationJson);

  }, [loadLocaleData]);
  return (
    <>
      {messages && (
        <IntlProvider locale={i18n} defaultLocale="de" messages={messages}>
          {children}
        </IntlProvider>
      )}
    </>
  );
};

export default Locales;