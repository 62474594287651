import { Navigate } from 'react-router-dom';
import usePrivateKey from 'hooks/usePrivateKey';

const withAuth = (Component) => {
  return (props) => {
    const { isUserRight } = usePrivateKey();
    if (!isUserRight) {
      return <Navigate to="/archive/search" replace />;
    }

    return <Component {...props} />;
  };
};

export default withAuth;