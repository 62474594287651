import React, { createContext, useState, useEffect, useCallback } from 'react';
import { invoiceApiServices } from 'utils/axios';
import { clearCookieStorage } from 'utils/commonFunctions';

// Create Context
export const PrivateKeyContext = createContext();

const PrivateKeyProvider = ({ children }) => {
  const [privateKey, setPrivateKey] = useState(null);
  const [encryptionKey, setEncryptionKey] = useState(null);
  const [isUserRight, setIsUserRight] = useState(false);
  const [userId ,setUserId ] = useState('');
  const [isLogin ,setIsLogin ] = useState(false);

  const getConfigurations = useCallback(async () => {
    try {
      const response = await invoiceApiServices.get("/main/getMetadata");

      if (response.status === 200 && response.data.DATA) {
        const data = JSON.parse(atob(response.data.DATA));
        setPrivateKey(data.privateKey['key'] || {});
        setEncryptionKey(data.encryptionKey || "");
      }
      return null
    } catch (err) {
      console.error("Error fetching metadata:", err);
    }
  }, []);

  const logout = useCallback(() => clearCookieStorage(encryptionKey),[encryptionKey])

  useEffect(() => {
    getConfigurations();
  }, [getConfigurations]);


  return <PrivateKeyContext.Provider value={{ privateKey, encryptionKey, isUserRight, setIsUserRight,userId ,setUserId,logout,isLogin ,setIsLogin }}>{children}</PrivateKeyContext.Provider>;
};

export default PrivateKeyProvider;