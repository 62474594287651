import axios from 'axios';
import Cookies from 'js-cookie';

const getDomain=()=>{
  const url = new URL(window.location.href);
  const hostname = url.hostname;
  const parts = hostname.split('.');
  console.log('url:',process.env.REACT_APP_ACCOUNT_API_URL+parts.slice(-2).join('.'))
  return process.env.REACT_APP_ACCOUNT_API_URL+'.'+parts.slice(-2).join('.')+'/api/v1';
  // return 'http://testrahul.io-netgate.net:5100/api/v1';
  // return 'http://localhost:5000/api/v1'; 
}

const axiosServices = axios.create({ baseURL: getDomain() || 'http://localhost:3010/' });
const invoiceApiServices = axios.create({ baseURL: process.env.REACT_APP_INVOICE_API_URL});

// ==============================|| AXIOS - FOR MOCK SERVICES ||============================== //

invoiceApiServices.interceptors.request.use(
  (config) => {
      // Retrieve login information from cookies
      const login = Cookies.get('loginCookie'); 
    
      // If the user is not logged in, exit the interceptor
      if (!login) return config;
  
      // Set default for withCredentials if not already specified
      if (typeof config.withCredentials === 'undefined') {
        config.withCredentials = true;
      }
      // Set 'Accept-Language' header based on the presence of 'language' in headers
      config.headers['Accept-Language'] = config.headers['Accept-Language'] || 'en';    
      return config;
  },
  (error) => {
    // Handle the error
    return Promise.reject(error);
  }
);

invoiceApiServices.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401 && !window.location.href.includes('/login')) {
      window.location = '/login';
    }
    return Promise.reject((error.response && error.response.data) || 'Wrong Services');
  }
);

axiosServices.interceptors.request.use(
  (config) => {
      // Retrieve login information from cookies
      const login = Cookies.get('loginCookie'); 
    
      // If the user is not logged in, exit the interceptor
      if (!login) return config;
  
      // Set default for withCredentials if not already specified
      if (typeof config.withCredentials === 'undefined') {
        config.withCredentials = true;
      }
      // Set 'Accept-Language' header based on the presence of 'language' in headers
      config.headers['Accept-Language'] = config.headers['Accept-Language'] || 'en';    
      return config;
  },
  (error) => {
    // Handle the error
    return Promise.reject(error);
  }
);

axiosServices.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401 && !window.location.href.includes('/login')) {
      window.location = '/login';
    }
    return Promise.reject((error.response && error.response.data) || 'Wrong Services');
  }
);

export {invoiceApiServices}
export default axiosServices;
