import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
// import { useNavigate } from 'react-router';

// material-ui
import { Box, Grid, Stack, Tooltip, Typography } from '@mui/material';
// import { Box, ButtonBase, CardContent, ClickAwayListener, Grid, Paper, Popper, Stack, Tab, Tabs, Tooltip, Typography } from '@mui/material';

// project import
// import ProfileTab from './ProfileTab';
// import SettingTab from './SettingTab';
import Avatar from 'components/@extended/Avatar';
import IconButton from 'components/@extended/IconButton';

// assets
import avatar1 from 'assets/images/users/avatar-1.png';
import { LogoutOutlined, } from '@ant-design/icons';
import useLocalStorage from 'hooks/useLocalStorage';
import { useIntl } from 'react-intl';
import usePrivateKey from 'hooks/usePrivateKey';
// import { LogoutOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons';

// tab panel wrapper
function TabPanel({ children, value, index, ...other }) {
  return (
    <div role="tabpanel" hidden={value !== index} id={`profile-tabpanel-${index}`} aria-labelledby={`profile-tab-${index}`} {...other}>
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

// function a11yProps(index) {
//   return {
//     id: `profile-tab-${index}`,
//     'aria-controls': `profile-tabpanel-${index}`
//   };
// }

// ==============================|| HEADER CONTENT - PROFILE ||============================== //

const Profile = () => {
  const [user, setUser] = useState('');
  // const navigate = useNavigate();
  const [userName] = useLocalStorage('userName', '');

  const { logout } = usePrivateKey();

  useEffect(() => {

    if (userName) {
      setUser(userName);
    }
  }, []);

  const handleLogout = async () => {
    try {
      await logout();
      // navigate(`/archive/search`, {
      //   state: {
      //     from: ''
      //   }
      // });
    } catch (err) {
      console.error(err);
    }
  };

  // const [value, setValue] = useState(0);

  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  const intl = useIntl();
  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <Stack direction="row" spacing={2} alignItems="center" sx={{ p: 0.5 }}>
        <Box>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Stack direction="row" spacing={1.25} sx={{ ml: .5, mr: 1 }} alignItems="center">
                <Avatar alt="profile user" src={avatar1} sx={{ width: 32, height: 32 }} />
                <Box sx={{height: "100%", p: 0, m:0}}>
                  <Typography sx={{p:0,m:0, lineHeight:0}} variant="h6">{user}</Typography>
                </Box>
              </Stack>
            </Grid>
            <Grid item>
              <Tooltip title={intl.formatMessage({ id: `logout` })}>
                <IconButton size="medium" sx={{ color: 'text.primary' }} onClick={handleLogout}>
                  <LogoutOutlined />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Box>
      </Stack>
    </Box>
  );
};

export default Profile;
