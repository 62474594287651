import React, { createContext, useContext, useState, useEffect,useRef } from 'react';
import useLocalStorage from './useLocalStorage';
import axiosServices from 'utils/axios';
import { useTheme } from '@mui/material/styles';
import { useIntl } from 'react-intl';
import { Box, Chip, Stack } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { formatDate,formatValidDate, isValidDate } from 'utils/commonFunctions';
import { useLocation , useParams ,useNavigate} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { openSnackbar } from 'store/reducers/snackbar';
import { ConfigContext } from 'contexts/ConfigContext';
import usePrivateKey from './usePrivateKey';

const SearchContext = createContext();

export const SearchProvider = ({ children }) => {
  const [searchValue, setSearchValue] = useLocalStorage('searchValue', '');
  const [startDate, setStartDate] = useLocalStorage('startDate', null);
  const [endDate, setEndDate] = useLocalStorage('endDate', null);
  const [documentType, setDocumentType] = useLocalStorage('documentType', '');
  const [documentCategory, setDocumentCategory] = useLocalStorage('documentCategory', '');
  const [invoiceNumber, setInvoiceNumber] = useLocalStorage('invoiceNumber', '');
  const [countryOfOrigin, setCountryOfOrigin] = useLocalStorage('countryOfOrigin', '');
  const [documentNumber, setDocumentNumber] = useLocalStorage('documentNumber', '');
  const [documentFields, setDocumentFields] = useLocalStorage('documentFields', {});
  const [profile, setProfile] = useLocalStorage('profile', '');
  const [company, setCompany] = useLocalStorage('company', '');
  const [results, setResults] = useLocalStorage('results', []);
  const [resultSize, setResultSize] = useLocalStorage('resultSize', 0);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(15);
  const [isDisableExportBtn, setIsDisableExportBtn] = useState(true);
  const [searchPageIndex, setSearchPageIndex] = useLocalStorage('searchPageIndex', 0);
  const [searchPageSize, setsearchPageSize] = useLocalStorage('searchPageSize', 15);
  const [isLoading, setIsLoading] = useState(false);
  const [sortOrderDesc, setSortOrderDesc] = useLocalStorage('sort', true);
  const [pendingSearch, setPendingSearch] = useState(false);
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const [showAccordion, setShowAccordion] = useState(false);
  const [activeFilters, setActiveFilters] = useState([]);
  const [profiledata] = useLocalStorage('profiledata');
  const [userName] = useLocalStorage('userName');
  const [dynamicField]=useLocalStorage('dynamicField');
  const { metasearchQuery } = useParams();
  const { companyQuery } = useParams();
  const isInitialMount = useRef(true);
  const { pathname } = useLocation();
  const possibleCurrencies = ['CHF', 'EUR', 'USD'];
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { i18n } = useContext(ConfigContext);
  const theme = useTheme();
  const intl = useIntl();
  const { userId } = usePrivateKey();

  useEffect(()=>{
    if(results.length>0 ){
      setIsDisableExportBtn(false);
    }else{
      setIsDisableExportBtn(true);
    }
  },[results])

  const escapeReservedSymbols = (input) => {
    const reservedSymbols = /[+\-=&|!(){}[\]^"~*?:\\/]/g; 
    return input.replace(reservedSymbols, '\\$&'); 
  };

  const parseCustomUrlParams = (path) => {
    const parts = path.split('@');
    const params = {};
    parts.forEach(part => {
      const [key, value] = part.split(':');
      if (key && value) {
        params[key] = decodeURIComponent(value);
      }
    });
    return params;
  };

  const triggerSearch = async () => {
    let isSingleSearch = false;
    setIsLoading(true);
    setIsDisableExportBtn(true);
    try {
      let wildCard = {};
      if (searchValue.trim() !== '') {
        wildCard = {  
          meta:  '*'+escapeReservedSymbols(searchValue.trim())+'*'
        }
      }

      const mustArray = [];
      const profileData = JSON.parse(localStorage.getItem('profiledata'));

      if (!profileData?.isAdminUser) {
        const permissionUserData = profileData?.userPermissions || '';
        mustArray.push({ match: { "permission_group": permissionUserData } });
      }

      if (invoiceNumber) mustArray.push({ match: { single_invoice: invoiceNumber } });
      if (countryOfOrigin) mustArray.push({ match: { country: countryOfOrigin } });
      if (documentNumber) mustArray.push({ match: { document_number: documentNumber } });
      if (profile) mustArray.push({ match: { profile: '*' + profile + '*' } });
      if (documentType) mustArray.push({ match: { document_type: '*' + documentType + '*' } });
      if (documentCategory) mustArray.push({ match: { category: '*' + documentCategory + '*' } });
      if (company) mustArray.push({ match: { company: '*' + company + '*' } });

      for (const [key, value] of Object.entries(documentFields)) {
        if (key.endsWith('_date') && value) {
          mustArray.push({ match: { [key]: formatValidDate(value) } }); // Add match object to array
        }else{   
          // mustArray.push({ match: { [key]: value } });
          mustArray.push(
                { match_phrase_prefix: { [key]: value } } // Match phrase prefix query
          );
         
        }
      }


      setShowAccordion(false);
      const filterArray = [];

      const { isStartDateValid, isEndDateValid } = isValidDate(startDate, endDate);

      if (startDate && endDate && isStartDateValid && isEndDateValid) {
        filterArray.push({
          range: {
            archived_datetime: {
              gte: formatDate(startDate, true),
              lte: formatDate(endDate, false),
            },
          },
        });
      } else if (startDate && isStartDateValid && !endDate) {
        filterArray.push({
          range: {
            archived_datetime: { gte: formatDate(startDate, true) },
          },
        });
      } else if (endDate && isEndDateValid && !startDate) {
        filterArray.push({
          range: {
            archived_datetime: { lte: formatDate(endDate, false) },
          },
        });
      } else if (!isStartDateValid || !isEndDateValid) {
        setShowAccordion(true);
        throw new Error("Invalid Date");
      }


      // if (searchValue) filterArray.push({ match_phrase_prefix:wildCard });
      if (searchValue) {
        const searchValueArray = searchValue.split(' ');
        if(searchValueArray.length>1){
          isSingleSearch = true;
          filterArray.push({ match_phrase_prefix:wildCard });
          filterArray.push({ wildcard:wildCard });
        }else{
          isSingleSearch = true;
          filterArray.push({ wildcard:wildCard });
          filterArray.push({ match_phrase_prefix:wildCard });
        }
      }

      const requestBody = {
        query: isSingleSearch ? { bool: { must: mustArray, should: filterArray,minimum_should_match: 1 } } : { bool: { must: mustArray, filter: filterArray } },
        from: pageIndex * pageSize || 0,
        size: pageSize,
        sort: { ['archived_datetime']: { order: sortOrderDesc ? 'desc' : 'asc' } },
      };

      const formData = new FormData();
      formData.append('query', JSON.stringify(requestBody));
      formData.append('index', documentCategory || '*');

      const response = await axiosServices.post('/main/listDocuments', formData);

      if (response.status !== 200) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      setResults(response.data.data || []);
      setResultSize(response.data.filterCount || 0);
      setSearchPageIndex(response.data.from / response.data.size ?? 0)
      setsearchPageSize(response.data.size ?? 15)
      setIsLoading(false);
      if(response.data.data.length>0){
        setIsDisableExportBtn(false);
      }
      const newActiveFilters = [];
      if (searchValue) newActiveFilters.push({ label: searchValue, key: 'searchValue' });
      if (startDate) newActiveFilters.push({ label: 'startDate', key: 'startDate' });
      if (endDate) newActiveFilters.push({ label: 'endDate', key: 'endDate' });
      if (invoiceNumber) newActiveFilters.push({ label: 'invoiceNum', key: 'invoiceNumber' });
      if (countryOfOrigin) newActiveFilters.push({ label: 'country', key: 'countryOfOrigin' });
      if (documentNumber) newActiveFilters.push({ label: 'document_number', key: 'documentNumber' });
      if (documentType) newActiveFilters.push({ label: 'document_type', key: 'documentType' });
      if (documentCategory) newActiveFilters.push({ label: 'category', key: 'category' });
      if (profile) newActiveFilters.push({ label: 'profile', key: 'profile' });
      if (company) newActiveFilters.push({ label: 'company', key: 'company' });
    
      Object.entries(documentFields).forEach(([key, value]) => {
        if (value) newActiveFilters.push({ label: key, key });
      });
    
      setActiveFilters(newActiveFilters);

    } catch (error) {
      console.error('Error fetching data: ', error);
      setResults([]);
      setResultSize(0);
      setIsLoading(false);
    }
  };

  const triggerSearchExport = async () => {
    if(!userId)return
    setIsLoading(true);
    let isSingleSearch = false;
    try {
      let wildCard = {};
      if (searchValue.trim() !== '') {
        wildCard = {  
          meta:  '*'+escapeReservedSymbols(searchValue.trim())+'*'
        }
      }

      const mustArray = [];
      const profileData = JSON.parse(localStorage.getItem('profiledata'));

      if (!profileData?.isAdminUser) {
        const permissionUserData = profileData?.userPermissions;
        if (permissionUserData) {
          mustArray.push({ match: { "permission_group": permissionUserData } });
        }

      }

      if (invoiceNumber) mustArray.push({ match: { single_invoice: invoiceNumber } });
      if (countryOfOrigin) mustArray.push({ match: { country: countryOfOrigin } });
      if (documentNumber) mustArray.push({ match: { document_number: documentNumber } });
      if (profile) mustArray.push({ match: { profile: '*' + profile + '*' } });
      if (documentType) mustArray.push({ match: { document_type: '*' + documentType + '*' } });
      if (documentCategory) mustArray.push({ match: { category: '*' + documentCategory + '*' } });
      if (company) mustArray.push({ match: { company: '*' + company + '*' } });

      for (const [key, value] of Object.entries(documentFields)) {
        if (key.endsWith('_date') && value) {
          mustArray.push({ match: { [key]: formatValidDate(value) } }); // Add match object to array
        }else{   
          // mustArray.push({ match: { [key]: value } });
          mustArray.push(
                { match_phrase_prefix: { [key]: value } } // Match phrase prefix query
          );
         
        }
      }

    //   setShowAccordion(false);
      const filterArray = [];

      const { isStartDateValid, isEndDateValid } = isValidDate(startDate, endDate);

      if (startDate && endDate && isStartDateValid && isEndDateValid) {
        filterArray.push({
          range: {
            archived_datetime: {
              gte: formatDate(startDate, true),
              lte: formatDate(endDate, false),
            },
          },
        });
      } else if (startDate && isStartDateValid && !endDate) {
        filterArray.push({
          range: {
            archived_datetime: { gte: formatDate(startDate, true) },
          },
        });
      } else if (endDate && isEndDateValid && !startDate) {
        filterArray.push({
          range: {
            archived_datetime: { lte: formatDate(endDate, false) },
          },
        });
      } else if (!isStartDateValid || !isEndDateValid) {
        setShowAccordion(true);
        throw new Error("Invalid Date");
      }


      // if (searchValue) filterArray.push({ match_phrase_prefix:wildCard });
      if (searchValue) {
        const searchValueArray = searchValue.split(' ');
        if(searchValueArray.length>1){
          isSingleSearch = true;
          filterArray.push({ match_phrase_prefix:wildCard });
          filterArray.push({ wildcard:wildCard });
        }else{
          isSingleSearch = true;
          filterArray.push({ wildcard:wildCard });
          filterArray.push({ match_phrase_prefix:wildCard });
        }
      }

      const requestBody = {
        query: isSingleSearch ? { bool: { must: mustArray, should: filterArray,minimum_should_match: 1 } } : { bool: { must: mustArray, filter: filterArray } }
      };

      let language = ['en', 'fr'].includes(i18n) ? i18n.toUpperCase() : 'DE';
      
      const formData = new FormData();
      formData.append('query', JSON.stringify(requestBody));
      formData.append('language', language);
      formData.append('userId', userId);
      formData.append('index', documentCategory || '*');

      const response = await axiosServices.post('/main/exportRecords', formData);

      if (response.status !== 200) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      dispatch(
        openSnackbar({
          open: true,
          message: 'pending',
          variant: 'exportNotification',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right'
          },
          close: true
        })
      )
      // let isSingleSearch = false;

      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data: ', error);
      setIsLoading(false);
    }
  };

  const searchBoxApi = async (query) => {
    setIsLoading(true);
    try {
      const queryArray = query.split(' ') || [];
    
      const wildCard = {
        query: '*'+escapeReservedSymbols(query.trim())+'*',
        fields: [
            'meta'
        ],
        analyze_wildcard: true
      }
 
      const requestBody = {
        query: {
          bool: queryArray.length > 1  ? {
              filter:{
                match_phrase_prefix: {
                  meta: '*'+escapeReservedSymbols(query.trim())+'*',
                } 
              }
            }: 
            {
              filter:{
                query_string: wildCard 
              }
            }
          },
          size: 5};

      const formData = new FormData();
      formData.append('query', JSON.stringify(requestBody));
      formData.append('index', '*'); // Search all indexes

      const response = await axiosServices.post('/main/listDocuments', formData);

      if (response.status !== 200) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      setIsLoading(false);
      return response.data.data || [];
    } catch (error) {
      console.error('Error fetching search box data: ', error);
      setIsLoading(false);
      return [];
    }
  };

  useEffect(() => {
    console.log("metasearchQuery:",metasearchQuery);
    
    const isValidCompany = ['hilcona', 'io-market', 'repower'].includes(companyQuery);
    if (metasearchQuery && isValidCompany && profiledata?.instance === companyQuery) {
      resetAllFilters()
      const params = parseCustomUrlParams(metasearchQuery);

      if (params.metasearch) {
        setSearchValue(params.metasearch);
      }
      if (params.profile) {
        setProfile(params.profile);
      }
      if (params.unternehmen) {
        setCompany(params.unternehmen);
      }
      if (params.doctype) {
        setDocumentType(params.doctype);
      }
      if (params.Dokumentart) {
        setDocumentCategory(params.Dokumentart);
      }
      if (params.docnr) {
        setDocumentNumber(params.docnr);
      }
      if(Object.values(params).length > 0){
        setPendingSearch(true);

      }
    }
  }, [metasearchQuery]);

  useEffect(() => {
    const isUrlSearchRoute = /\/:companyQuery/.test(pathname.pathname)
    const isValidCompany = ['hilcona', 'io-market', 'repower'].includes(companyQuery);
    
    if (isUrlSearchRoute && (!isValidCompany || profiledata?.instance !== companyQuery)) {
      console.log("invalid metasearch");
      resetAllFilters()
      navigate('/archive/search');
      localStorage.removeItem('companyQuery');
      return;
    }
    localStorage.setItem('companyQuery', companyQuery);
  }, [companyQuery, profiledata]);

  // const getActiveFilters = () => {
  //   const activeFilters = [];
  //   if (searchValue) activeFilters.push({ label: searchValue, key: 'searchValue' });
  //   if (startDate) activeFilters.push({ label: 'startDate', key: 'startDate' });
  //   if (endDate) activeFilters.push({ label: 'endDate', key: 'endDate' });
  //   if (invoiceNumber) activeFilters.push({ label: 'invoiceNum', key: 'invoiceNumber' });
  //   if (countryOfOrigin) activeFilters.push({ label: 'country', key: 'countryOfOrigin' });
  //   if (documentNumber) activeFilters.push({ label: 'document_number', key: 'documentNumber' });
  //   if (documentType) activeFilters.push({ label: 'document_type', key: 'documentType' });
  //   if (documentCategory) activeFilters.push({ label: 'category', key: 'category' });
  //   if (profile) activeFilters.push({ label: 'profile', key: 'profile' });

  //   Object.entries(documentFields).forEach(([key, value]) => {
  //     if (value) activeFilters.push({ label: key, key });
  //   });

  //   return activeFilters;
  // };

  const clearAllFilters = () => {
    setSearchValue('');
    setStartDate(null);
    setEndDate(null);
    setDocumentType('');
    setDocumentCategory('');
    setInvoiceNumber('');
    setCompany('');
    setCountryOfOrigin('');
    setDocumentNumber('');
    setDocumentFields({});
    setProfile('');
    setPendingSearch(true);
  };
  const resetAllFilters = () => {
    setSearchValue('');
    setStartDate(null);
    setEndDate(null);
    setDocumentType('');
    setDocumentCategory('');
    setInvoiceNumber('');
    setCompany('');
    setCountryOfOrigin('');
    setDocumentNumber('');
    setDocumentFields({});
    setProfile('');
    setResults([]);
    setResultSize(0);
  };
  const handleDeleteFilter = (filterKey) => {
    switch (filterKey) {
      case 'searchValue':
        setSearchValue('');
        break;
      case 'startDate':
        setStartDate(null);
        break;
      case 'endDate':
        setEndDate(null);
        break;
      case 'documentType':
        setDocumentType('');
        break;
      case 'category':
        setDocumentCategory('');
        setDocumentFields({});
        break;
      case 'invoiceNumber':
        setInvoiceNumber('');
        break;
      case 'company':
        setCompany('');
        break;
      case 'countryOfOrigin':
        setCountryOfOrigin('');
        break;
      case 'documentNumber':
        setDocumentNumber('');
        break;
      case 'profile':
        setProfile('');
        break;
      default:
        if (Object.keys(documentFields).includes(filterKey)) {
          setDocumentFields((prevFields) => {
            const updatedFields = { ...prevFields };
            delete updatedFields[filterKey];
            return updatedFields;
          });
        }
        break;
    }
    setPendingSearch(true);
  };

  const FilterDisplay = () => (
    <Box
      className="sec_align"
      sx={{
        pb: 0.7,
        overflowY: 'auto',
        width: '100%',
        '&::-webkit-scrollbar': {
          height: '2px',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: theme.palette.primary.dark,
          borderRadius: '4px',
        },
        '&::-webkit-scrollbar-track': {
          background: theme.palette.background.main,
        },
      }}
    >
      {activeFilters.length > 0 && (
        <Stack direction="row" spacing={1} alignItems="center">
          {activeFilters.map((filter, index) => (
            <Chip
              key={index}
              label={filter.key === 'searchValue' ? searchValue : intl.formatMessage({ id: filter.label })}
              onDelete={() => handleDeleteFilter(filter.key)}
              deleteIcon={<CloseIcon />}
              sx={{
                backgroundColor: theme.palette.primary.lighter,
                color: theme.palette.primary.main,
                border: 1,
                borderColor: theme.palette.primary.dark,
                '& .MuiChip-deleteIcon': {
                  color: theme.palette.primary.dark,
                  '&:hover': {
                    color: theme.palette.primary.main,
                  },
                },
              }}
            />
          ))}

          {/* Clear All Button */}
          {activeFilters.length > 1 && (
            <Chip
              label={intl.formatMessage({ id: 'clearAll' })}
              onClick={clearAllFilters}
              sx={{
                color: theme.palette.secondary.dark,
                border: 1,
                borderColor: theme.palette.secondary.main,
                cursor: 'pointer',
              }}
            />
          )}
        </Stack>
      )}
    </Box>
  );

  useEffect(() => {
    if (pendingSearch) {
      // Perform search once after state updates are done
      triggerSearch();

      // Reset pendingSearch to false
      setPendingSearch(false);
    }
  }, [pendingSearch]);

  useEffect(() => {
    setPageIndex(0)
  }, [searchValue]);

  useEffect(() => { 
    const urlParams = parseCustomUrlParams(pathname);
    setResults([]);
    if (isInitialMount.current) {
      isInitialMount.current = false; 
      if(Object.values(urlParams).length === 0){
        clearAllFilters();
      }
    } else {
      triggerSearch();
    }
  }, [searchValue, pageIndex, pageSize,sortOrderDesc,pathname]);

  return (
    <SearchContext.Provider value={{
      searchValue, setSearchValue,
      startDate, setStartDate,
      endDate, setEndDate,
      documentType, setDocumentType,
      documentCategory, setDocumentCategory,
      invoiceNumber, setInvoiceNumber,
      countryOfOrigin, setCountryOfOrigin,
      documentNumber, setDocumentNumber,
      documentFields, setDocumentFields,
      profile, setProfile,
      results, setResults,
      resultSize, setResultSize,
      pageIndex, setPageIndex,
      pageSize, setPageSize,
      isLoading,setIsLoading,
      sortOrderDesc, setSortOrderDesc, searchPageIndex, searchPageSize,
      triggerSearch,
      triggerSearchExport,
      activeFilters,
      clearAllFilters,
      handleDeleteFilter,
      parseCustomUrlParams,
      FilterDisplay,
      searchBoxApi,
      isDisableExportBtn,
      isSearchFocused, setIsSearchFocused,
      showAccordion, setShowAccordion,
      companyQuery,
      userName,
      possibleCurrencies,
      company, setCompany,
      dynamicField
    }}>
      {children}
    </SearchContext.Provider>
  );
};

export const useSearch = () => {
  const context = useContext(SearchContext);
  if (context === undefined) {
    throw new Error('useSearch must be used within a SearchProvider');
  }
  return context;
};
