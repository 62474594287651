const jwt = require('jsonwebtoken');
const CryptoJS = require('crypto-js');

const createToken = (data,privatekey) => {
  
  const privateKey = privatekey
  const paramsOption = {
    algorithm: 'RS256',
    expiresIn: '2 days'
  };
  const formattedPrivateKey = formatPrivateKey(privateKey);
  const token = jwt.sign(data, formattedPrivateKey, paramsOption);
  return token;
};
const formatPrivateKey = (privateKey) => {
  const begin = '-----BEGIN RSA PRIVATE KEY-----\n';
  const end = '\n-----END RSA PRIVATE KEY-----';
  // Use a regular expression to insert newlines every 64 characters
  const formattedKey = privateKey.replace(/(.{64})/g, '$1\n');

  return `${begin}${formattedKey}${end}`;
};
const encrypt = (data,encryptionKey) => {
  const ivKey = encryptionKey;
  if (ivKey) {
    const binaryEncryptionKey = CryptoJS.enc.Base64.parse(ivKey);
    const cipher = CryptoJS.AES.encrypt(data, binaryEncryptionKey, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    return cipher.toString();
  }
};

const decrypt = (encryptedData,encryptionKey) => {
  const ivKey = encryptionKey;
  if (ivKey) {
    const binaryEncryptionKey = CryptoJS.enc.Base64.parse(ivKey);
    const bytes = CryptoJS.AES.decrypt(encryptedData, binaryEncryptionKey, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    return bytes.toString(CryptoJS.enc.Utf8);
  }
};


const formatDateByLocale = (date, locale) => {
  // Check if the input is in ISO 8601 format (YYYY-MM-DDTHH:mm:ss.sssZ)
  if (typeof date === 'string' && date.includes('T')) {
    const dateObj = new Date(date);

    if (isNaN(dateObj.getTime())) {
      console.error('Invalid date');
      return date;
    }

    // Format the date according to the specified locale
    if (locale === 'en' || locale === 'fr') {
      // English format: MM/DD/YYYY hh:mm:ss AM/PM
      const ampm = dateObj.getHours() >= 12 ? 'PM' : 'AM';
      const formattedHours = dateObj.getHours() % 12 || 12; // Convert 24h to 12h format
      return `${(dateObj.getMonth() + 1).toString().padStart(2, '0')}/${dateObj.getDate().toString().padStart(2, '0')}/${dateObj.getFullYear()} ${formattedHours.toString().padStart(2, '0')}:${dateObj.getMinutes().toString().padStart(2, '0')}:${dateObj.getSeconds().toString().padStart(2, '0')} ${ampm}`;
    } else {
      // German format: DD.MM.YYYY HH:mm:ss
      return `${dateObj.getDate().toString().padStart(2, '0')}.${(dateObj.getMonth() + 1).toString().padStart(2, '0')}.${dateObj.getFullYear()} ${dateObj.getHours().toString().padStart(2, '0')}:${dateObj.getMinutes().toString().padStart(2, '0')}:${dateObj.getSeconds().toString().padStart(2, '0')}`;
    }
  }

  // Custom format handling: DD.MM.YYYY or DD.MM.YYYY HH:mm:ss
  if (typeof date !== 'string') {
    console.error('Invalid input: expected a string in DD.MM.YYYY or DD.MM.YYYY HH:mm:ss format');
    return date;
  }

  const dateRegex1 = /^(\d{4})-(\d{2})-(\d{2})\s(\d{2}):(\d{2}):(\d{2})$/;
  const matchDate = date.match(dateRegex1);

  if (matchDate) {
    const [, year, month, day, hours, minutes, seconds] = matchDate;

    const formatdate = (locale === 'de') 
    ? `${day}.${month}.${year} ${hours}:${minutes}:${seconds}` 
    : `${month}/${day}/${year} ${hours}:${minutes}:${seconds}`;

    return formatdate
  }

  const dateRegex = /^(\d{2})\.(\d{2})\.(\d{4})(?:\s(\d{2}):(\d{2}):(\d{2}))?$/;
  const match = date.match(dateRegex);

  if (!match) {
    console.error('Invalid date format. Expected DD.MM.YYYY or DD.MM.YYYY HH:mm:ss');
    return date;
  }

  const [, day, month, year, hours = '00', minutes = '00', seconds = '00'] = match;
  const dateObj = new Date(year, month - 1, day, hours, minutes, seconds);

  if (isNaN(dateObj.getTime())) {
    console.error('Invalid date');
    return date;
  }

  // Format the date for the specified locale
  if (locale === 'en' || locale === 'fr') {
    const ampm = dateObj.getHours() >= 12 ? 'PM' : 'AM';
    const formattedHours = dateObj.getHours() % 12 || 12;
    return `${(dateObj.getMonth() + 1).toString().padStart(2, '0')}/${dateObj.getDate().toString().padStart(2, '0')}/${dateObj.getFullYear()} ${formattedHours.toString().padStart(2, '0')}:${dateObj.getMinutes().toString().padStart(2, '0')}:${dateObj.getSeconds().toString().padStart(2, '0')} ${ampm}`;
  } else {
    return `${dateObj.getDate().toString().padStart(2, '0')}.${(dateObj.getMonth() + 1).toString().padStart(2, '0')}.${dateObj.getFullYear()} ${dateObj.getHours().toString().padStart(2, '0')}:${dateObj.getMinutes().toString().padStart(2, '0')}:${dateObj.getSeconds().toString().padStart(2, '0')}`;
  }
};



function clearCookieStorage(encryptionKey,hotReload = false) {

  (async () => {
    let cookies = document.cookie.split("; ");
    for (let c = 0; c < cookies.length; c++) {
      let d = window.location.hostname.split(".");
      while (d.length > 0) {
        let cookieBase = encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' + d.join('.') + ' ;path=';
        let p = window.location.pathname.split('/');
        document.cookie = cookieBase + '/';
        while (p.length > 0) {
          document.cookie = cookieBase + p.join('/');
          p.pop();
        }
        d.shift();
      }
    }
    const host = window.location.host;
    const parts = host.split(".");
    parts.shift();
    await redirectToLogin(encryptionKey);
    localStorage.clear();
  })();
  if (hotReload)
    window.location.reload();
}

const formatDate = (date, isStartOfDay = true) => {
  const localDate = new Date(date);

  if (isStartOfDay) {
    localDate.setHours(0, 0, 0, 0);
  } else {
    localDate.setHours(23, 59, 59, 999);
  }

  const year = localDate.getFullYear();
  const month = String(localDate.getMonth() + 1).padStart(2, '0');
  const day = String(localDate.getDate()).padStart(2, '0');

  // Return the formatted date as ISO string
  return `${year}-${month}-${day}T${isStartOfDay ? '00:00:00.000' : '23:59:59.999'}`;
};
const formatValidDate = (date = true) => {
  const localDate = new Date(date);
  const year = localDate.getFullYear();
  const month = String(localDate.getMonth() + 1).padStart(2, '0');
  const day = String(localDate.getDate()).padStart(2, '0');

  // Return the formatted date as ISO string
  return `${day}.${month}.${year}`;
};
const isValidDate = (startDate, endDate) => {
  const start = new Date(startDate);
  const end = new Date(endDate);
  const today = new Date();

  start.setHours(0, 0, 0, 0);
  end.setHours(0, 0, 0, 0);
  today.setHours(0, 0, 0, 0);

  // Validate start date: it should be a valid date and <= today
  const isStartDateValid = !isNaN(start.getTime()) && start <= today;

  // Validate end date: it should be a valid date and >= start date
  const isEndDateValid = !isNaN(end.getTime()) && end >= start;
  return { isStartDateValid, isEndDateValid };
};

const redirectToLogin = async (encryptionKey) => {
  let redirectUrl = localStorage.getItem('redirectLogin');
  let encryptedData = await encrypt(window.location.href,encryptionKey);

  let result = redirectUrl.replace(/"/g, '');

  await window.location.assign(result + 'auth/login/' + encodeURIComponent(encryptedData));

}

// New Validation Function for Text Inputs - it checks for letters
const validateTextInput = (value) => /^[A-Za-zäöüÄÖÜß\s]*$/.test(value);

const checkVersionMismatch = (latestVersion, currentVersion) => {
  const latest = latestVersion.split('.');
  const current = currentVersion.split('.');

  while (latest.length || current.length) {
    const a = Number(latest.shift());
    const b = Number(current.shift());

    if (a === b) continue;
    return a > b || isNaN(b);
  }

  return false;
};


module.exports = {
  encrypt,
  decrypt,
  formatDateByLocale,
  clearCookieStorage,
  redirectToLogin,
  formatDate,
  formatValidDate,
  isValidDate,
  validateTextInput,
  checkVersionMismatch,
  createToken
};
