import { useContext } from 'react';

// auth provider
import { PrivateKeyContext } from 'contexts/PrivateKeyContext';

// ==============================|| PRIVATE KEY HOOKS ||============================== //

const usePrivateKey = () => {
  const context = useContext(PrivateKeyContext);

  if (!context) throw new Error('context must be use inside provider');

  return context;
};

export default usePrivateKey;
