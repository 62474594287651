// import { Link as RouterLink } from 'react-router-dom';

// material-ui
import { Link, Stack, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

const Footer = () => (
  <Stack direction="row" justifyContent="flex-start" alignItems="center" sx={{ pt: '24px', mt: 'auto',ml:'-19px' }}>
    <Typography variant="caption" sx={{color:'#4c4f53'}}>&copy; 2025 iomarket &nbsp;|&nbsp;</Typography> 
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      {/* <Link component={RouterLink} to="#" variant="caption" color="textPrimary">
        <FormattedMessage id='about_us'>

        </FormattedMessage>
      </Link> */}
      <Link href="https://www.io-market.com/AGB.pdf" target="_blank" rel="noopener noreferrer" variant="caption" sx={{color:'#4c4f53'}}>
        <FormattedMessage id='terms'/>
        &nbsp;|&nbsp;
      </Link>
      <Link href="https://www.io-market.com/ADV.pdf" target="_blank" rel="noopener noreferrer" variant="caption" sx={{color:'#4c4f53'}}>
        <FormattedMessage id='data_processing_agreement'/>
        &nbsp;|&nbsp;
      </Link> 
      <Link href="https://www.io-market.com/privacy_policy.pdf" target="_blank" rel="noopener noreferrer" variant="caption" sx={{color:'#4c4f53'}}>
        <FormattedMessage id='privacy'/>
        &nbsp;|&nbsp;
      </Link> 
      <Link href="https://www.io-market.com/impressum.pdf" target="_blank" rel="noopener noreferrer" variant="caption" sx={{ color: '#4c4f53' }}>
        <FormattedMessage id='legal_notices'/>
      </Link>
    </Stack>
  </Stack>
);

export default Footer;
